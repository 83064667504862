const checkStatus = (response) => {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
        return response
    } else if (response.status === 401) {
        window.location.reload(false);  // TODO: Look into using a refresh token? For now refreshing page
        return response;
    } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
    }
}

const callApi = async (url, options={}, header=null) => {
    const headers = header ? header : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }

    var serverUrl = 'http://localhost:5000';
    // var serverUrl = 'https://dev-fablement.com';
    if(process.env['NODE_ENV'] === 'production')
        serverUrl = process.env['NEXT_PUBLIC_PROTOCOL_URL'] + process.env['NEXT_PUBLIC_DOMAIN_NAME'];

    const res = await fetch(new URL(url, serverUrl), {
        headers,
        ...options
    })
    // .then(res => checkStatus())
    // .then(response => response.json())
    return res.json();
}

const callApiFromServer = async (ctx, url, options={}, header=null) => {
    const headers = header ? header : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'cookie': ctx.req.headers.cookie,
    }

    return await callApi(url, options, headers);
}

const encodeQueryData = (data) => {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    if(ret.length > 0)
        return '?' + ret.join('&');
    return '';
 }

const buildUrl = ({url = null, select = null, where = null, limit = null, offset = null, orderby = null}) => {
    if(!url)
        throw(new Error('Url must be defined!'))
    var query = {};
    // if(select !== null)
    //     query.select = select.toString();
    if(where !== null)
        query.where = where.toString();
    if(limit !== null)
        query.limit = limit.toString();
    if(offset !== null)
        query.offset = offset.toString();
    if(orderby !== null)
        query.orderby = orderby.toString();
    var lastChar = url[url.length -1];
    if(lastChar !== '/')
        url = url + '/'
    return url + encodeQueryData(query);
}

const storyUrl = (title) => {
    var newTitle = title.replace(/ /g, "_");
    const cleanUrl = newTitle.replace(/[^\w+$]/gi, '')
    
    return encodeURIComponent(cleanUrl);
}

const baseUrl = (url) => {
    return url.split('?')[0];
}

export {callApi, callApiFromServer, buildUrl, storyUrl,baseUrl}