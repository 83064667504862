import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
config.autoAddCss = false; /* eslint-disable import/first */
import { 
    faAngleDoubleLeft, faAngleDoubleRight, faFileDownload, faFileUpload, faFile, faFileAlt, 
    faCheck, faLink, faCalculator, faMousePointer, faHandRock, faSearchPlus, faSearchMinus, 
    faCog, faGlobe, faQuestion, faSave, faPlus, faTimes, faClone, faTrash, faEdit, 
    faFlagCheckered, faRandom, faBold, faItalic, faUnderline, faCode, faAngleDoubleUp, 
    faAngleUp, faQuoteLeft, faListOl, faListUl, faPlay, faEraser, faFolderOpen, faCrosshairs,
    faBookMedical, faAddressCard, faSignOutAlt, faMoon, faSun, faPencilAlt, faEnvelope, faHistory,
    faHeart, faClock, faLifeRing, faImage, faFont, faRemoveFormat, faWrench, faCommentAlt, faBookmark,
    faStar, faTrophy, faThumbsUp, faThumbsDown, faAngleDown, faBars, faHome, faBell, faKeyboard, faGlasses,
    faFeatherAlt, faUser, faShare, faExpand, faUndoAlt, faRedoAlt, faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { 
    fab, faFacebookSquare, faGoogle, faPatreon
} from '@fortawesome/free-brands-svg-icons'
// import { closeSync } from 'fs';
library.add(
    faAngleDoubleLeft, faAngleDoubleRight, faFileDownload, faFileUpload, faFile, faFileAlt, 
    faCheck, faLink, faCalculator, faMousePointer, faHandRock, faSearchPlus, faSearchMinus, 
    faCog, faGlobe, faQuestion, faSave, faPlus, faTimes, faClone, faTrash, faEdit, 
    faFlagCheckered, faRandom, faBold, faItalic, faUnderline, faCode, faAngleDoubleUp, 
    faAngleUp, faQuoteLeft, faListOl, faListUl, faPlay, faEraser, faFolderOpen,
    faCrosshairs, faBookMedical, faAddressCard, faSignOutAlt, 
    faMoon, faSun, faPencilAlt, faEnvelope, faHistory, faHeart, faClock, faLifeRing, faImage,
    faFont, faRemoveFormat, faWrench, faCommentAlt, faBookmark, faStar, faTrophy, faThumbsUp, 
    faThumbsDown, faAngleDown, faBars, faHome, faBell, faKeyboard, faGlasses, faFeatherAlt, faUser, 
    faShare, faExpand, faUndoAlt, faRedoAlt, faExclamationTriangle,
    fab, faFacebookSquare, faGoogle, faPatreon
)

class Icon extends Component {
    static propTypes = {
        name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
        counterClass: PropTypes.string,
        counterValue: PropTypes.string,
        color: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
        size: PropTypes.number,
        innerIcon: PropTypes.string,
        innerColor: PropTypes.string,
        innerClassName: PropTypes.string,
        innerSize: PropTypes.number,
        prefix: PropTypes.string,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        name: null,
        counterClass: null,
        counterValue: null,
        color: '',
        className: '',
        size: 1,
        innerIcon: null,
        innerColor: '',
        innerClassName: '',
        innerSize: 1,
        prefix: 'fas',
    };

    getIconHtmlClean = (prefix, name, size, color, style) => {
        // const iconClassName = `${prefix} fa-${name} ${className}`;
        const iconStyle = Object.assign({}, style, {
            fontSize: size ? `${size}em` : '1em',
            color,
        });
        return (
            <FontAwesomeIcon icon={name} style={iconStyle} onClick={this.props.onClick}/>
        );
    }

    render() {
        const { 
            name, color, size, iconStyle,
            innerIcon, innerColor, innerSize, innerStyle,
            prefix, counterClass, counterValue 
        } = this.props;

        const iconHtml = this.getIconHtmlClean(prefix, name, size, color, iconStyle);

        // stack icons
        if (innerIcon) {
            const innerIconHtml = this.getIconHtmlClean(prefix, innerIcon, innerSize, innerColor, innerStyle);
            return (
                <span className="fa-stack fa-layers fa-fw">
                    {iconHtml}
                    {innerIconHtml}
                </span>
            );
        // counter class
        } else if (counterClass) {
            return (
                <span className="fa-layers fa-fw">
                    {iconHtml}
                    {counterClass ? (
                        <span className={counterClass} style={{background:"Tomato", zoom: "120%"}}>{counterValue}</span>
                    ) : null}
                </span>
            );
        // just icon
        } else {
            return iconHtml;
        }
    }
}

export default Icon;
