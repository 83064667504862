import React from 'react';

import useWindowDimensions from '../helpers/useWindowDimensions';

const NoStretchDiv = ({id, children, className, style, ratio=(16 / 9)}) => {
    const { width, height } = useWindowDimensions();

    const max169AspectRatioDiv = () => {
        const maxWidth = height * ratio;
        return {width: '100%', maxWidth: Math.max(maxWidth, width) + 'px', margin: 'auto'};
    }

    return (
        <div id={id} className={className} style={Object.assign({}, max169AspectRatioDiv(), style)}>
            {children}
        </div>
    )
}

export default NoStretchDiv;
